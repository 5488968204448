import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Blogs = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
  return (
      <Layout>
      <SEO  keywords={[`Website performance Optimization`, `Website development`, `Custom charts`]}
            title="Blog"/>  
      <div className="flex mx-auto w-full items-center justify-center">
        <ul className="flex flex-col p-4 w-full items-center justify-center">

          {posts.map(post => {
            const title = post.frontmatter.title;
            return (
              <li  className="border-gray-400 flex flex-row mb-4 w-full lg:w-3/4" key={post.frontmatter.slug}>
                <Link to={post.frontmatter.slug + '/'} itemProp="url" className="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div className="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center">
                  <div className="uppercase flex flex-col rounded-md w-20 h-20 bg-gray-500 justify-center items-center mr-4 text-5xl font-thin text-gray-800">
                    {title.substr(0, 1)}
                  </div>
                  <div className="flex-1 pl-1 mr-16">
                    <div className="font-extrabold text-xl lg:text-3xl">
                      
                        <span itemProp="headline">{title}</span>
                      
                    </div>
                    <div className="text-gray-600 text-sm pt-4">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.html,
                        }}
                        itemProp="html"
                      />
                    </div>
                  </div>
                  <div className="text-gray-600 text-xs">{post.frontmatter.date}</div>
                </div>
                </Link>          
              </li>
            )
          })}

          
        
        
        </ul>
      </div>


      
    </Layout>    
  )
  }

Blogs.propTypes = {
  data: PropTypes.node.isRequired,
  location: PropTypes.node.isRequired,
};


  export default Blogs;

  export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          slug
        
        }
      }
    }
  }
`